var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[(_vm.isFetching)?_c('div',{staticClass:"ma-6"},[_c('table-skeleton',{attrs:{"withActions":false}})],1):_c('v-card',{staticClass:"rounded-xl mt-4 mx-5 pt-4 px-6",attrs:{"flat":""}},[_c('div',{staticClass:"d-flex py-4"},[_c('div',{staticClass:"d-flex align-center"},[_c('p',{staticClass:"mr-2",attrs:{"id":"title"}},[_vm._v(" All Searches ")]),_c('primary-button',{attrs:{"button-text":"Create New Search","handleClick":_vm.createSearch,"customClass":"primary-button"}})],1),_c('v-spacer'),_c('v-text-field',{staticClass:"search-input",attrs:{"prepend-inner-icon":"mdi-magnify","outlined":"","label":"Search","dense":""},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1),_c('v-data-table',{staticClass:"mx-2",attrs:{"headers":_vm.headers,"items":_vm.savedSearchesItems,"search":_vm.searchQuery,"items-per-page":15,"footer-props":{
        disablePagination: false,
        showCurrentPage: true,
        rowsPerPage: 15
      }},scopedSlots:_vm._u([{key:"item.ssName",fn:function(ref){
      var item = ref.item;
return [_c('router-link',{staticClass:"pr-2",attrs:{"to":'/document-search/' + item.ssID}},[_vm._v(" "+_vm._s(item.ssName)+" ")])]}},{key:"item.ssCreationDate",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatCreatedDate(item.ssCreationDate))+" ")]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){return _vm.handleCancellationModal(item)}}},[_vm._v(" mdi-trash-can ")])]}}],null,true)})],1),(_vm.savedSearchToBeDeleted)?_c('div',[_c('simple-confirmation-modal',{attrs:{"cancelButtonText":"Cancel","confirmButtonText":"Yes, Delete","modalTitle":"Delete this search?","modalText":"Are you sure you want to delete this search? This action is permanent.","handleCloseModal":_vm.handleCancellationModal,"isModalDisplayed":_vm.isCancelModalDisplayed,"handleConfirmSelection":_vm.deleteSavedSearch,"isConfirmButtonDisabled":_vm.isConfirmDeletionButtonDisabled}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
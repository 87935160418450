





































































// Custom components
import PrimaryButton from "@/components/design-system/buttons/PrimaryButton.vue";
import SimpleConfirmationModal from "@/components/design-system/modals//SimpleConfirmationModal.vue";
import TableSkeleton from "@/components/skeletons/TableSkeleton.vue";

// Constants
import options from "@/shared/constants/toast-options";
import { STATUS_CODES } from "@/shared/constants/http";

// Interfaces
import { SavedSearch } from "@/interfaces/advanced-search/saved-search-interfaces";

// Services
import { advancedSearchService } from "@/services/advanced-searches.service";

// Third party
import { Component, Vue } from "vue-property-decorator";
import { ToastOptions } from "vue-toasted";

// Utils
import { utils } from "@/utils/okta-utils";

@Component({
  components: {
    "primary-button": PrimaryButton,
    "table-skeleton": TableSkeleton,
    "simple-confirmation-modal": SimpleConfirmationModal
  }
})
export default class SavedDocumentSearches extends Vue {
  private isFetching = false;
  private searchQuery = "";
  private savedSearchesItems: SavedSearch[] = [];
  private isCancelModalDisplayed = false;
  private savedSearchToBeDeleted: null | SavedSearch = null;
  private isConfirmDeletionButtonDisabled = false;
  private headers = [
    {
      text: "Name",
      align: "start",
      sortable: true,
      value: "ssName"
    },
    {
      text: "Date Created",
      align: "start",
      sortable: true,
      value: "ssCreationDate",
      width: "25%"
    },
    {
      text: "Actions",
      align: "end",
      sortable: true,
      value: "actions",
      width: "90px",
      border: "solid"
    }
  ];

  // methods
  createSearch() {
    this.$router.push("/document-search");
  }

  formatCreatedDate(date: Date) {
    return utils.utcToLocalDateString(date, "L");
  }

  handleCancellationModal(savedSearch: SavedSearch | null) {
    this.isCancelModalDisplayed = !this.isCancelModalDisplayed;
    this.savedSearchToBeDeleted = savedSearch ?? null;
  }

  removeSavedSearchFromUIAfterDeletion() {
    this.savedSearchesItems = this.savedSearchesItems.filter(
      x => x.ssID != this.savedSearchToBeDeleted?.ssID
    );
  }

  showToaster(toastedOption: ToastOptions, message: string) {
    this.$toasted.show(`<p>${message}</p>`, toastedOption);
  }

  async fetchSavedSearches() {
    this.isFetching = true;
    this.savedSearchesItems =
      (await advancedSearchService.getSavedSearches()) ?? [];
    this.isFetching = false;
  }

  async deleteSavedSearch() {
    if (this.savedSearchToBeDeleted) {
      this.isConfirmDeletionButtonDisabled = true;

      const apiResponse = await advancedSearchService.deleteSavedSearch(
        this.savedSearchToBeDeleted.ssID
      );

      let toastType = options.ERROR_OPTIONS;
      let toastMessage =
        apiResponse.errorMessage || "Error occurred while deleting search";

      if (apiResponse.statusCode == STATUS_CODES.OK) {
        toastType = options.SUCCESS_OPTIONS;
        toastMessage = "Search deleted";
        this.removeSavedSearchFromUIAfterDeletion();
      }

      this.isCancelModalDisplayed = false;
      this.savedSearchToBeDeleted = null;
      this.isConfirmDeletionButtonDisabled = false;
      this.showToaster(toastType, toastMessage);
    }
  }

  // Lifecycle hooks
  async created() {
    await this.fetchSavedSearches();
  }
}
